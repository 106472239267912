import React, { useState } from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CImg
} from '@coreui/react';
import Swal from "sweetalert2"; 
import logo from '../../assets/logo.png';
import loadingImage from '../../assets/loader.gif';

const SetPassword = ({ match }) => {
    const { id, email } = match.params;
    const [buttonLoading, setButtonLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const handleSubmit = () => {
        if(password === "" || confirmPassword === ""){
            Swal.fire({  
                title: 'ERROR',  
                icon: 'error',   
                text: 'All fields are required!',  
            });
        } else {
            const data = {
                password: password,
                confirmPassword: confirmPassword,
                resetToken: id,
                email: email
            }
            setButtonLoading(true);
            fetch(window.backendDomain+'/api/v1/staff/set-password', {
                method: 'POST',
                headers: {
                'Content-type': 'application/json',
                'auth-token': localStorage.getItem('token')
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (json.status === 'OK') {
                    Swal.fire({  
                        title: json.title,  
                        icon: 'success',   
                        text: json.message,  
                    });
                } else {
                    Swal.fire({  
                        title: json.title,  
                        icon: 'error',   
                        text: json.message,  
                    });
                }
                setButtonLoading(false);
            });
        }
    }
    let submitButton = <CButton color="danger" className="px-4 w-100" onClick={() => handleSubmit()}>Save</CButton>;
    switch (buttonLoading) {
        case false:
            submitButton = <CButton color="danger" className="px-4 w-100" onClick={() => handleSubmit()}>Save</CButton>;
        break;
        case true:
            submitButton = <CButton color="danger" className="px-4 w-100 disabled"><img src={loadingImage} alt="LoadingImage" style={{width: '20px'}} /> Loading</CButton>
        break;
        default:
            submitButton = <CButton color="danger" className="px-4 w-100" onClick={() => handleSubmit()}>Save</CButton>;
    }
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                <CCol md="5">
                    <center>
                    <CImg
                        src={logo}
                        alt="OyaWatch"
                        width={'50%'} 
                    />
                    <h3>Set Your Password</h3>
                    </center>
                    <CCardGroup>
                    <CCard className="p-4">
                        <CCardBody id="login-card-body">
                        
                            <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                                <CInputGroupText>
                                <i className="fa fa-key"></i>
                                </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput type="password" placeholder="Password" autoComplete="password" onChange={(e) => setPassword(e.target.value)} />
                            </CInputGroup>
                            <CInputGroup className="mb-4">
                            <CInputGroupPrepend>
                                <CInputGroupText>
                                <i className="fa fa-key"></i>
                                </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput type="password" placeholder="Password" autoComplete="password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            </CInputGroup>
                            <CRow>
                            <CCol xs="12">
                                {submitButton}
                            </CCol>
                            </CRow>
                        
                        </CCardBody>
                        <center>Powered by Intradot</center>
                    </CCard>
                    </CCardGroup>
                </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default SetPassword
